import {
	type LinksFunction,
	type LoaderFunctionArgs,
	json,
} from "@remix-run/cloudflare";
import {
	Links,
	Meta,
	Outlet,
	Scripts,
	ScrollRestoration,
	useNavigate,
	useRouteLoaderData,
} from "@remix-run/react";
import type { ReactNode } from "react";
import { RouterProvider, useLocale } from "react-aria-components";
import { Toaster } from "sonner";
import { cn } from "~/helpers/cn";

import tailwind from "~/tailwind.css?url";
import { SEO } from "./components/seo";
import { i18n, saveLocale } from "./core/language.server";
import { seo } from "./helpers/seo.server";

export const handle: App.Handle = { i18n: ["translation"] };

export const links: LinksFunction = () => [
	{ rel: "preload", as: "style", href: tailwind },
	{ rel: "stylesheet", href: tailwind },
];

export async function loader({ request }: LoaderFunctionArgs) {
	let locale = await i18n.getLocale(request);
	let t = await i18n.getFixedT(locale, "translation", { keyPrefix: "meta" });

	let headers = new Headers();
	await saveLocale(headers, locale);

	return json({ locale, seo: seo({ title: t("title") }) }, { headers });
}

export default function Component() {
	return <Outlet />;
}

export function Layout({ children }: { children: ReactNode }) {
	let navigate = useNavigate();

	let { locale, direction } = useLocale();
	let loaderData = useRouteLoaderData<typeof loader>("root");

	return (
		<RouterProvider navigate={navigate}>
			<html
				lang={loaderData?.locale || locale}
				dir={direction}
				className="dark:pattern bg-gradient-to-b bg-white from-white to-accent-100 text-neutral-950 dark:bg-black dark:from-black dark:via-black dark:to-accent-950 dark:text-neutral-50"
			>
				<head>
					<meta charSet="utf-8" />
					<meta name="viewport" content="width=device-width,initial-scale=1" />
					<Meta />
					<SEO />
					<Links />
				</head>

				<body className={cn("v-stack min-h-dvh w-screen")}>
					{children}
					<Toaster />
					<ScrollRestoration />
					<Scripts />
				</body>
			</html>
		</RouterProvider>
	);
}
