import {
	type MetaDescriptor,
	type UIMatch,
	useMatches,
} from "@remix-run/react";

/**
 * The SEO component grabs the last match with a `seo` property and renders the
 * meta tags and title from it.
 *
 * This way, you can use the `seo` helper in your route loaders without adding
 * the `MetaFunction` export to every route.
 */
export function SEO() {
	let seo = SEO.useSEO();
	return (
		<>
			{seo?.map((metaProps) => {
				if ("tagName" in metaProps) {
					let { tagName, ...rest } = metaProps;
					if (!isValidMetaTag(tagName)) {
						console.warn(
							`A meta object uses an invalid tagName: ${tagName}. Expected either 'link' or 'meta'`,
						);
						return null;
					}
					let Comp = tagName;
					return <Comp key={JSON.stringify(rest)} {...rest} />;
				}

				if ("title" in metaProps) {
					return <title key="title">{String(metaProps.title)}</title>;
				}

				if ("charset" in metaProps) {
					metaProps.charSet ??= metaProps.charset;
					// biome-ignore lint/performance/noDelete: This is ok
					delete metaProps.charset;
				}

				if ("charSet" in metaProps && metaProps.charSet != null) {
					return typeof metaProps.charSet === "string" ? (
						<meta key="charSet" charSet={metaProps.charSet} />
					) : null;
				}

				if ("script:ld+json" in metaProps) {
					try {
						let json = JSON.stringify(metaProps["script:ld+json"]);
						return (
							<script
								key={`script:ld+json:${json}`}
								type="application/ld+json"
								// biome-ignore lint/security/noDangerouslySetInnerHtml: This is ok
								dangerouslySetInnerHTML={{ __html: json }}
							/>
						);
					} catch (err) {
						return null;
					}
				}
				return <meta key={JSON.stringify(metaProps)} {...metaProps} />;
			})}
		</>
	);
}

function isValidMetaTag(tagName: unknown): tagName is "meta" | "link" {
	return typeof tagName === "string" && /^(meta|link)$/.test(tagName);
}

export namespace SEO {
	type SEOUIMatch = UIMatch<{ seo: MetaDescriptor[] }, unknown>;

	export function useSEO() {
		let matches = useMatches();
		let match = matches.findLast((match) => {
			if (!match.data) return false;
			if (typeof match.data !== "object") return false;
			if (Array.isArray(match.data)) return false;
			return "seo" in match.data;
		}) as SEOUIMatch | undefined;

		if (!match) {
			console.warn(
				"Could not find a match with a `seo` property. Make sure you're using the `seo` helper in your route loader.",
			);
			return null;
		}

		return match.data.seo;
	}
}
